@import 'init';

.contentWrapper {
    background: $content-bg;
    padding: 1rem;
    flex-shrink: 1;

    h1:nth-child(1) {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
    }

    @include border-radius();
}

.richTextContent {
    @include media-breakpoint-up(md) {
        img[src$='#left'] {
            float: left;
            margin-right: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;
        }

        img[src$='#right'] {
            float: right;
            margin-left: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;
        }
    }

    h1,
    h2,
    h3 {
        clear: both;
    }
}
