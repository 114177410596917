@import '../init';

.footer {
    margin-top: auto;
    padding-top: 2rem;

    img {
        width: 150px;
    }

    :global .container {
        background: $content-bg;
        padding: 1rem;
        @include border-radius();
    }

    .rightColumn {
        text-align: right;
    }
}
