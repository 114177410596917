.thumbnails {
    img {
        object-fit: cover;
        cursor: pointer;
    }

    :global .embed-responsive {
        margin-bottom: 1rem;
    }
}

.authorBlock {
    margin: 1rem 0;

    h3 {
        margin-bottom: 1rem;
    }
}
