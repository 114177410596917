@import 'init';
@import '~bootstrap/scss/bootstrap';
@import '~react-image-lightbox/style.css';

html,
body {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
